import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
 
const AnyReactComponent = ({ text }) => (
  <div style={{
    color: 'white', 
    background: 'rgb(65, 182, 230)',
   padding: '25px 10px 25px 10px',
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    transform: 'translate(-50%, -50%)'
  }}>
    {text}
  </div>
);
 
export class MapContainer extends Component {
  static defaultProps = {
    center: {
      lat: 40.6389334,
      lng: -74.1303669
    },
    zoom: 15
  };
 
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '20rem', width: '20rem' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCMNJTxi-TSJiQ1yk1bkpkg5WUr8DoE6wQ" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={this.props.center.lat}
            lng={this.props.center.lng}
            text="CircuitPro"
          />
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default MapContainer;