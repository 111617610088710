import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import Home from "pages/Home.js";
import About from "pages/AboutUs.js";
import Services from "pages/Services.js"
import ContactUs from "pages/ContactUs.js"
import ContactSuccess from "pages/ContactSuccess.js"
import Salesforce from "pages/Salesforce.js"

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


export default function App() {
  // return <AnimationRevealPage disabled></AnimationRevealPage>;
  return (
    <Router>
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/services">
          <Services />
        </Route>
        <Route path="/contact">
          <ContactUs />
        </Route>
          <Route path="/contact-success">
          <ContactSuccess />
        </Route>
          <Route path="/sf">
          <Salesforce />
        </Route>        
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}
