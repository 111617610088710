import React, { Component }  from "react";
import {Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import CheckCircle from "images/check-circle.svg";
import ReactLoading from 'react-loading';

import ContactSuccessComp from "components/features/ContactSuccessComp.js";
const Subheading = tw.span`uppercase tracking-wider text-sm`;

const Example = ({ type, color }) => (
  <ReactLoading type={'cylon'} color={'#41b6e6'} height={'10%'} width={'10%'} />
);

export default class ContactSuccess extends Component {
  state = {
    redirect: false
  }

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ redirect: true }), 3000)
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }

  render() {
    return this.state.redirect
      ? <Redirect to="/" />
      :<AnimationRevealPage>
       <Header />
       <Container>
       <ContentWithPaddingXl>

       
        <ContactSuccessComp
        subheading={<Subheading>Your Message has been recieved</Subheading>}
        heading=""
        description=""
        buttonRounded={false}
        primaryButtonText="See Services"
        primaryButtonUrl="/services"
        imageSrc=""
      />
      <Example/>
      <p>redirecting... </p>
       </ContentWithPaddingXl>
       <Helmet>
 <script>{`gtag('event', 'conversion', {'send_to': 'AW-435206038/JHQQCP_qiJMDEJbvws8B'});`}</script>
</Helmet>
       </Container>
      <Footer />
    </AnimationRevealPage>
  }
}

