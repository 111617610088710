import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import ReCaptcha from "../../helpers/ReCaptcha.js"

import $ from 'jquery';


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-secondary-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`;
 
// Load the required clients and packages
const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const {
  fromCognitoIdentityPool,
} = require("@aws-sdk/credential-provider-cognito-identity");
const { S3Client, PutObjectCommand, ListObjectsCommand, DeleteObjectCommand, DeleteObjectsCommand } = require("@aws-sdk/client-s3");

// Set the AWS Region
const REGION = "us-east-1"; //REGION

// Initialize the Amazon Cognito credentials provider
const s3 = new S3Client({
  region: REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: REGION }),
    identityPoolId: "us-east-1:5f6f4252-5eea-4f4d-90e1-1251a9bab4fb", // IDENTITY_POOL_ID
  }),
});

const bucketName = "circuitprousa-uploads"; //BUCKET_NAME




       
// Add a photo to an album
const addPhoto = async (e) => {
  e.preventDefault();

  var form = $('#formID');
  var url = form.attr('action');


  const files = document.getElementById("s3upload").files;
  try {
    
    // const albumPhotosKey =  files.name;
    const data = await s3.send(
        new ListObjectsCommand({
          // Prefix: albumPhotosKey,
          Bucket: bucketName
        })
    );
    const file = files[0];
    const fileName = file.name;
    const photoKey = fileName;
    const uploadParams = {
      Bucket: bucketName,
      Key: photoKey,
      Body: file
    };
    
    try {
      const data = await s3.send(new PutObjectCommand(uploadParams));
      document.getElementById('00N4W00000Rt3vH').value="https://circuitprousa-uploads.s3.amazonaws.com/"+fileName;
      document.getElementById("formID").submit();
      // $.ajax({
      //   type: "POST",
      //   method: "POST",
      //   url: url,
      //   data: form.serialize(),
      //   headers: {
      //     'Access-Control-Allow-Origin': '*'
      // },
      // processData: false,
      // contentType: false,
      // crossOrigin: true,
      // crossDomain: true,
      //   success: function(data)
      //   {
      //       alert(data); // show response from the php script.
      //   }
      // });
    } catch (err) {
      return alert("There was an error uploading your photo: ", err.message);
    }
  } catch (err) {
    if (!files.length) {
      return alert("Choose a file to upload first.");
    }
  }
};
// Make addPhoto function available to the browser
window.addPhoto = addPhoto;







export default () => {
// function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500); 

// var $recaptcha = document.querySelector('#g-recaptcha-response');
// console.log("hi hi");
//     if($recaptcha) {
//         $recaptcha.setAttribute("required", "required");
//     }
  return (

    <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2>Contact Us</h2>
            <form id="formID" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">

              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="first_name">First Name</Label>
                    <Input required id="first_name" maxlength="40" name="first_name" size="20" type="text" />
                    {/* <Input type="hidden" name='captcha_settings' value='{"keyname":"mht_cmiller_local","fallback":"true","orgId":"00D4W000005irhz","ts":""}'/> */}
                    <Input type="hidden" name="oid" value="00D4W000005irhz"/>
                    <Input type="hidden" name="retURL" value="https://circuitprousa.com/contact-success"/>
                    <Input type="hidden" name="debugEmail" value="christopher.j.mill@gmail.com" />
                    <Input type="hidden" name="lead_source" value="Website" />

                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="last_name">Last Name</Label>
                      <Input required id="last_name" maxlength="80" name="last_name" size="20" type="text" />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="company">Company</Label>
                    <Input required id="company" maxlength="40" name="company" size="20" type="text" />
                  </InputContainer>  
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                      <Input required id="email" maxlength="80" name="email" size="20" type="email" />
                  </InputContainer>                
                </Column>
                <Column>
                  <InputContainer tw="flex-auto">
                    <Label htmlFor="description">Your Message</Label>
                    <TextArea required id="message-input" name="description" />
                   
                  </InputContainer>
                  <InputContainer>
                  <Label>Your Project File</Label>
                   <input type="file" id="s3upload" name="filename"></input>
                   <input  id="00N4W00000Rt3vH" maxlength="255" name="00N4W00000Rt3vH" size="20" type="hidden" />
                   </InputContainer>
                  <InputContainer>
                  <br/>
                  {/* <div class="g-recaptcha" data-sitekey="6LekGkYaAAAAAL5onr_XymW7GIR37sG6lHKei_o_"></div> */}
                  </InputContainer>
                </Column>            
              </TwoColumn>
              
              <SubmitButton onClick={addPhoto}>Submit</SubmitButton>
              
            </form>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
