import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import Chip1 from "../images/chip-1.jpg";
import Chip2 from "../images/chip-4.jpg";
const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About CircuitPro</Subheading>}
        heading=""
        description="CircuitPro has a been in PCB manufacturing for over a decade now with state of the art equipment helping developers produce a quick prototypes"
        buttonRounded={false}
        primaryButtonText="See Services"
        primaryButtonUrl="/services"
        imageSrc={Chip1}
      />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading=""
        description="CircuitPro offers a team of experts in PCB design, manufacturing process and staff of engineers to walk the costumer through a design as well as go into a full scale production"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="/contact"
        imageSrc={Chip2}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>CircuitPro Lighting Standards and Certifications</Subheading>}
        heading=""
        description=""
        cards={[
          {
            
            description: "AS9100D/ISO9001:2015 Certified"
          },
          {
            
            description: "UL Approved"
          },
          {
            
            description: "BAA compliant"
          },
          {
            
            description: "TAA compliant"
          },
          {
            
            description: "RoHS Certification"
          },
          {
            
            description: "IPC Member"
          },          
        ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};
