import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Helmet } from "react-helmet";

// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Hero from "components/hero/FullWidthWithImage.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Pricing from "components/pricing/ThreePlans.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/GetStartedLight.js";
import GetStarted from "components/cta/GetStarted.js";
import Footer from "components/footers/MiniCenteredFooter.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import CTA from "components/cta/GetStartedLight.js";
const HighlightedText = tw.span`text-primary-500`

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <FeatureStats/>
      <Features />   
{ /*      <Pricing 
        heading={<>Featured Specials <HighlightedText>Specifications</HighlightedText></>}
      />
*/ }
<CTA/>
      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      />
     {/* <GetStarted/> */}
      <Footer />

    </AnimationRevealPage>



  );
}
